<template>
  <div class="home">
    <div class="section section1">
      <h1>简介快速，协同开发</h1>
      <p class="sub-title">专业、可靠、简洁、易懂的Api协作平台</p>
      <p>
        <router-link to="/user/register">
          <a-button type="primary" class="register-btn">
            <span>立即注册</span>
            <a-icon type="right-circle" />
          </a-button>
        </router-link>
      </p>

      <a-carousel autoplay arrows>
        <div v-for="img in carouselImgs" :key="img.title" class="carousel-item">
          <img :src="img.url" :alt="img.title" />
        </div>
        <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow">
          <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow">
          <a-icon type="right-circle" />
        </div>
      </a-carousel>
    </div>

    <div class="section section2 gray-bg">
      <h2>多重安全策略，让接口更安全，使用更放心</h2>
      <p class="btns">
        <!-- <a>
          <img :src="require('@/assets/home/home_win.png')" alt="win">
          <span>Windows</span>
        </a>
        <a>
          <img :src="require('@/assets/home/home_ios.png')" alt="ios">
          <span>MacOS</span>
        </a> -->
      </p>
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="20">
            <a-col
              v-for="item in section2"
              :key="item.id"
              :xs="24"
              :sm="12"
              :md="6"
            >
              <div class="item">
                <img :src="item.img" alt="icon" />
                <p class="name">
                  <strong>{{ item.name }}</strong>
                </p>
                <p class="desc" v-for="(desc, index) in item.desc" :key="index">
                  <span>{{ desc }}</span>
                </p>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section3">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="16">
              <img
                :src="require('@/assets/home/sample2.jpg')"
                class="sample"
                alt="img"
              />
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">轻松上手，创作自如</p>
                <h2>团队协作</h2>
                <p class="desc">
                  团队/项目权限管理支持不同协同场景，满足不同企业和团队的需求。<br />
                  接口数据实时同步云端。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>成熟项目管理</span>
                  </li>
                  <li>
                    <i></i>
                    <span>高效团队管理</span>
                  </li>
                  <li>
                    <i></i>
                    <span>清晰权限管理</span>
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section4 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h2>自动生成代码</h2>
          <p class="desc">
            严格按照接口定义，一键生成各种语言/框架的业务代码和 API 请求代码。
          </p>
          <p class="desc">高效率把接口应用到实际业务场景，提升接口接入效率。</p>
          <img
            :src="require('@/assets/home/sample.jpg')"
            class="sample"
            alt="img"
          />
          <a-row :gutter="48">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">
                根据接口/模型定义，自动生成各种语言/框架的业务代码和 API
                请求代码。
              </div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">
                支持
                TypeScript、Java、Go、Swift、ObjectiveC、Kotlin、Dart、C++、C#、Rust
                等 130 种语言及框架。
              </div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="item">
                支持自定义代码模板，自动生成符合自己团队的架构规范的代码，满足各种个性化的需求。
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section5">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">对接开发，沟通顺畅</p>
                <h2>API 调试</h2>
                <p class="desc">
                  环境变量、前置/后置脚本、全局变量等人性化设计，让接口调试更顺手。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>自动化校验数据结构。</span>
                  </li>
                  <li>
                    <i></i>
                    <span>可视化的断言、提取变量操作。</span>
                  </li>
                  <li>
                    <i></i>
                    <span>接口用例轻松用。</span>
                  </li>
                </ul>
              </div>
            </a-col>
            <a-col :sm="24" :md="24" :lg="16">
              <img
                :src="require('@/assets/home/sample.jpg')"
                class="sample"
                alt="img"
              />
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section6 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <a-row :gutter="70">
            <a-col :sm="24" :md="24" :lg="16">
              <img
                :src="require('@/assets/home/sample.jpg')"
                class="sample"
                alt="img"
              />
            </a-col>
            <a-col :sm="24" :md="24" :lg="8">
              <div class="info">
                <p class="tooltip">随时随地，开启创作</p>
                <h2>API 数据 Mock</h2>
                <p class="desc">
                  无论在公司还是远程，只需打开浏览器，随时可以让工作无缝。
                </p>
                <ul>
                  <li>
                    <i></i>
                    <span>零学习成本使用mock服务，让前后端开发解耦。</span>
                  </li>
                  <li>
                    <i></i>
                    <span
                      >Mock数据更符合接口定义结构，让mock数据贴近真实。</span
                    >
                  </li>
                </ul>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section7">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h2>企业版</h2>
          <p class="desc">
            团队中角色都可以在企业版查看信息 <br />
            企业级项目文档管理、接口管理，让团队中每个人都如你一样高效
          </p>
          <img
            :src="require('@/assets/home/home_img01.png')"
            class="sample"
            alt="img"
          />
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>

    <div class="section section8 gray-bg">
      <h3>跟这些优秀团队一起使用ApiMini吧</h3>
    </div>

    <div class="section section9">
      <h3>战略合作伙伴</h3>
    </div>

    <div class="section section10 gray-bg">
      <a-row>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
        <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
          <h3>现在就使用ApiMini，开始无忧创作</h3>
          <div class="btn-wrapper">
            <a-input placeholder="simple@example.com" />
            <a-button type="primary">
              <span>立即注册</span>
              <a-icon type="right-circle" />
            </a-button>
          </div>
        </a-col>
        <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      carouselImgs: [
        { url: require('@/assets/home/sample.jpg'), title: 'dev' },
        {
          url: require('@/assets/home/sample2.jpg'),
          title: 'test',
        },
      ],
      section2: [
        {
          id: 1,
          name: '高效协作',
          desc: [
            '云端同步，更新强提醒；',
            '成熟团队、项目管理，让协作更轻松。',
          ],
          img: require('@/assets/home/home_icon01.png'),
        },
        {
          id: 2,
          name: 'api调试',
          desc: ['本地化postman调试功能，更高效；', '自动化数据结构校验。'],
          img: require('@/assets/home/home_icon02.png'),
        },
        {
          id: 3,
          name: 'api文档',
          desc: [
            '在线文档，让分享更方便；',
            '成熟团队、项目管理，让协作更轻松。',
          ],
          img: require('@/assets/home/home_icon03.png'),
        },
        {
          id: 4,
          name: 'api 数据mock',
          desc: ['内置高效引擎，高效mock数据；', 'mock数据符合参数规则。'],
          img: require('@/assets/home/home_icon04.png'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.home {
  text-align: center;
  .section {
    padding: 110px 0 150px;
    h1 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 43px;
      color: #182634;
    }
    h2 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 34px;
      color: #182634;
    }
    h3 {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 26px;
      color: #182634;
    }
    .sub-title {
      font-weight: 400;
      font-size: 21px;
      line-height: 28px;
      color: #182634;
    }
    .desc {
      font-size: 12px;
      line-height: 20px;
      color: #7e8791;
    }
    img.sample {
      width: 100%;
      height: auto;
    }
    &.gray-bg {
      background-color: #fafafa;
    }
  }

  .section3,
  .section5,
  .section6 {
    text-align: left;
    .info {
      padding-top: 30px;
    }
    .tooltip {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #0071bc;
    }
    ul {
      li {
        padding-left: 18px;
        margin-top: 20px;
        margin-bottom: 15px;
        position: relative;
        i {
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: #ff783f;
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
        }
      }
    }
  }

  .section1 {
    .register-btn {
      margin: 30px auto 50px;
      width: 162px;
      height: 52px;
      font-size: 15px;
      border-radius: 26px;
      color: #fffaf9;
    }
    .carousel-item {
      img {
        width: 70%;
        height: auto;
        margin: auto;
      }
    }
    .ant-carousel >>> .slick-slide {
      text-align: center;
      height: 160px;
      line-height: 160px;
      background: #364d79;
      overflow: hidden;
    }

    .ant-carousel >>> .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #fff;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }
    .ant-carousel >>> .custom-slick-arrow:before {
      display: none;
    }
    .ant-carousel >>> .custom-slick-arrow:hover {
      opacity: 0.5;
    }

    .ant-carousel >>> .slick-slide h3 {
      color: #fff;
    }
  }

  .section2 {
    .btns {
      margin: 30px 0 80px;
      a {
        margin: 0 20px;
        display: inline-block;
        width: 130px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        color: #4a525b;
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
        border-radius: 26px;
        img {
          margin-right: 5px;
        }
      }
    }

    .item {
      margin-bottom: 20px;
      padding: 25px;
      background: #ffffff;
      border: 1px solid #e8e9ec;
      box-shadow: -8px 30px 80px -25px rgba(82, 82, 82, 0.1);
      border-radius: 8px;
      text-align: left;
      img {
        width: auto;
        height: 30px;
      }
      .name {
        margin: 15px 0 10px;
        font-size: 18px;
        line-height: 24px;
        color: #182634;
        strong {
          font-weight: 400;
        }
      }
      .desc {
        margin: 0;
        font-size: 12px;
        line-height: 24px;
        color: #7e8791;
      }
    }
  }

  .section3 {
    background: url('../../assets/home/bg_img01.png') no-repeat left 40%;
    background-size: 20% auto;
  }

  .section4 {
    img {
      display: block;
      margin: 38px auto 48px;
    }
    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      margin-bottom: 25px;
      padding: 0 15px 0 20px;
      height: 100px;
      background: #f4f4f4;
      border-left: 5px solid #0071bc;
    }
  }

  .section5 {
    background: url('../../assets/home/bg_img03.png') no-repeat right 40%;
    background-size: 20% auto;
  }

  .section6 {
    background: url('../../assets/home/bg_img02.png') no-repeat left 40%;
    background-size: 20% auto;
  }

  .section10 {
    h3 {
      margin: 0 auto 50px;
      width: 500px;
      background: linear-gradient(
        90.1deg,
        #0071bc -0.9%,
        #6fbcf4 52.69%,
        #ff783f 99.96%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .btn-wrapper {
      width: 496px;
      height: 52px;
      margin: auto;
      background: #fff;
      border: 1px solid #208bc9;
      border-radius: 26px;
      position: relative;
      overflow: hidden;
      .ant-input {
        height: 52px;
        padding-left: 20px;
        text-align: left;
        font-size: 18px;
        background: none;
        border: none;
        box-shadow: none;
      }
      .ant-btn {
        width: 162px;
        height: 52px;
        font-size: 15px;
        border-radius: 26px;
        position: absolute;
        top: -1px;
        right: -1px;
      }
    }
  }
}
</style>
